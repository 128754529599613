import React, { useState } from "react";
//import Footer from "./components/Footer";
//import Header from "./components/Header";
import "./App.css";

const url = "https://helloacm.com/api/pi/?cached&n=1000000";

var piDigits = "";
var output = ""; //the output which is to be displayed
var n = NaN;
var i = "";
var themeIndex = JSON.parse(localStorage.themeIndex || 0) || {};
var colors = ["#2c8b9f", "#d33352", "#ee5253", "#266252", "#00857c",];

// Fetching the Pi digits from the API above.
// Referred from https://helloacm.com/pi/
fetch(url)
  .then((response) => response.json())
  .then((data) => (piDigits = data.substring(2)));

if (themeIndex.obj !== 0) {
  document.body.style.background = colors[themeIndex.obj];
}

// Change background
function changeBG() {
  i = themeIndex.obj;
  if (i < colors.length - 1) {
    i += 1;
  } else {
    i = 0;
  }
  document.body.style.background = colors[i];
  themeIndex.obj = i;
  themeIndex.time = new Date().getTime();
  localStorage.themeIndex = JSON.stringify(themeIndex);
}

// Text hider
function displayHide() {
  document.getElementById("info").style.visibility = "hidden";
  document.getElementById("input").style.paddingLeft = "1ch";
  document.getElementById("input").style.paddingRight = "1ch";
}
function displayShow() {
  document.getElementById("info").style.visibility = "visible";
  document.getElementById("input").style.paddingLeft = "0.5ch";
  document.getElementById("input").style.paddingRight = "0.5ch";
}

// Go to end of input if input is not empty
function moveCursorToEnd() {
  if (document.getElementById("input") != null) {
    document.getElementById("input").focus(); // focus input. used when not starting at input.
    document.getElementById("input").setSelectionRange(999999999, 999999999); // Go to end of input
    console.log("Input activated");
  }
}

// Load event listenders when page loads
window.onload = function () {
  // go to end of input when clicking input
  document
    .getElementById("input")
    .addEventListener("click", moveCursorToEnd, true);
  // Change background when double clicking
  window.addEventListener("dblclick", changeBG, true);
};

// APP
function App() {
  const [input, setInput] = useState("");
  function findInPi(input) {
    //Searching for the input digits in PiDigits
    //If found, the value entered is stored in n, else -1 is returned by indexOf
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/indexOf
    n = piDigits.indexOf(input);
    var isnum = /^\d+$/.test(n);
    console.log(isnum);

    // display starting text or not
    if (input.length === 0) {
      displayShow(); //Show info text
    } else {
      displayHide(); //Show info text
    }

    // if start is 3. then output = 3. + input
    if (input === "3.") {
      document.getElementById("input").value = "";
      document.getElementById("input").placeholder = "";
    }

    // Return answer
    if (n !== -1) {
      output = input;
      return output;
    } else {
      return "";
    }
  }
  return (
    <div className="App">
      <input
        type="text"
        value={input}
        onChange={(e) => {
          findInPi(e.target.value);
          setInput(e.target.value);
        }}
        className="input"
        placeholder="𝞹" //italic: 𝞹, normal: 𝞹
        name="input"
        id="input"
        inputMode="numeric"
        autoFocus={true}
      />
      <div className="output" id="output">
        {input === "" ? (
          <p style={{ userSelect: "none", cursor: "default" }}>
            Enter as many digits of pi as possible!
          </p>
        ) : (
          <div>
            {n === -1 ? (
              <p>INCORRECT!</p>
            ) : (
              <p>
                starting at: <span style={{ color: " #19222d" }}>#{n + 1}</span>{" "}
                - at position:{" "}
                <span style={{ color: " #19222d" }}>#{n + input.length}</span>
              </p>
            )}
            <p id="output_numbers">
              <span style={{ color: " #19222d" }}>
                {
                  input.replace(/(\d{10})/g, "$1 ").replace(/(^\s+|\s+$)/, "") // Chunks 10 digits at a time
                }
              </span>
              <span id="dotdotdot">{"..."}</span>
            </p>
          </div>
        )}
        {
          <p className="info" id="info">
            <span style={{ opacity: "0.8em" }}>
              {" "}
              Start anywhere after "3." within the first one million digits
            </span>
            <br />
            <span style={{ fontSize: "0.8em", opacity: "0.55" }}>
              Double click to change theme
            </span>
          </p>
        }
      </div>
    </div>
  );
}
export default App;
